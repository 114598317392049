import React from 'react';
import mainStyles from "./main.module.css";
import Hero from './components/hero_section';
import Clients from './components/clients';
import Lane from './components/lane_section';
import Shippers from './components/shippers_section';
import XblLynkit from './components/xbl_lynkit';
import MetricsBoxes from './components/metrics';
import Features from './components/key_features';
import Business from './components/business';
import Testimonials from './components/testimonials';
import Header from './components/header';
import FreightIndexing from './components/freightIndexing/page';
import FAQs from './components/faqs';
import Footer from './components/footer';
import Particle from './helper_components/particle';

const Xeneta = () => {
  const [selectedFeature, setSelectedFeature] = React.useState(0);
  return (
    <div
      className={mainStyles.landing_page}
    // style={{ overflowX: "hidden" }}
    >
      {/* Header */}
      <div
        className={mainStyles.section_wrapper}
        style={{ position: "relative" }}
      >
        <section
          className={mainStyles.section}
          style={{ position: 'relative' }}
        >
          <Header />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: "relative" }}
        >
          <Hero />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: "relative" }}
        >
          <Clients />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: "relative" }}
        >
          <XblLynkit />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: 'relative', overflow: "initial" }}
        >
          <Business />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: 'relative' }}
        >
          <FAQs />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: 'relative' }}
          id='features'
        >
          <Features setCurrent={setSelectedFeature} current={selectedFeature} />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: 'relative' }}
        >
          <Testimonials />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: "relative" }}
        >
          <MetricsBoxes />
        </section>

        <section
          className={mainStyles.section}
          style={{ position: "relative" }}
          id='lane'
        >
          <Lane />
        </section>

        {/* <section
          className={mainStyles.section}
          style={{ position: "relative" }}
        >
          <Shippers />
        </section> */}

        {/* <section
          className={mainStyles.section}
          style={{position:'relative'}}
        >
          <FreightIndexing/>
        </section> */}
        
        {/* <section
          className={mainStyles.section}
          style={{position:'relative', height: "100vh"}}
        >
          <Particle />
        </section> */}

        <section
          className={mainStyles.section}
          style={{ position: 'relative', background: "#000", height: "200px", width: "100%" }}
        >

        </section>

        <section
          className={mainStyles.section}
          style={{ position: 'relative' }}
        >
          <Footer />
        </section>
      </div>
    </div>
  )
}

export default Xeneta